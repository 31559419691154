import { gql } from "@apollo/client";

const GET_VENUE_SIZE = gql`
  query GetVenueSizes {
    venueSizes {
      id
      name
      venueTypeId
      note
    }
  }
`;

export { GET_VENUE_SIZE };
