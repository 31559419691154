import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import {
  CheckIcon,
  XCircle,
  ChevronDown,
  XIcon,
  WandSparkles,
} from "lucide-react";

import { cn } from "../../../../lib/utils";
import { Separator } from "../../shadcn/separator";
import { Button } from "../../shadcn/button";
import { Badge } from "../../shadcn/badge";
import { Popover, PopoverContent, PopoverTrigger } from "../../shadcn/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../shadcn/command";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Caption1 from "../../Text/Caption/Caption1";
import Body2 from "../../Text/Body/Body2";

/**
 * Variants for the multi-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
const multiSelectVariants = cva(
  "m-1 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300",
  {
    variants: {
      variant: {
        default: "text-black hover:bg-primary-90",
        secondary: "ext-secondary-foreground hover:bg-primary-95",
        destructive:
          "border-transparent text-destructive-foreground hover:bg-primary-95",
        inverted: "inverted",
        disabled: "border-2 border-neutral-60 focus:outline-none",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface MultiSelectRef {
  clearState: () => void;
  focus: () => void;
  getButtonElement: () => HTMLButtonElement | null;
}

/**
 * Props for FormFieldSelectMultiSearch component
 */
interface MultiSelectProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiSelectVariants> {
  /**
   * An array of option objects to be displayed in the multi-select component.
   * Each option object has a label, value, and an optional icon.
   */
  options: {
    /** The text to display for the option. */
    label: string;
    /** The unique value associated with the option. */
    value: string;
    /** Optional icon component to display alongside the option. */
    icon?: React.ComponentType<{ className?: string }>;
  }[];

  /**
   * Callback function triggered when the selected values change.
   * Receives an array of the new selected values.
   */
  onValueChange: (value: string[]) => void;

  /** The default selected values when the component mounts. */
  defaultValue?: string[];

  /**
   * Placeholder text to be displayed when no values are selected.
   * Optional, defaults to "Select options".
   */
  placeholder?: string;

  /**
   * Label displayed above the select.
   */
  label?: string;

  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * Tool tip trigger text
   */
  toolTipTrigger?: string;
  /**
   * Tool tip content text
   */
  toolTipContent?: string;
  /**
   * Maximum number of items to display. Extra selected items will be summarized.
   * Optional, defaults to 3.
   */
  maxCount?: number;

  /**
   * The modality of the popover. When set to true, interaction with outside elements
   * will be disabled and only popover content will be visible to screen readers.
   * Optional, defaults to false.
   */
  modalPopover?: boolean;

  /**
   * If true, renders the multi-select component as a child of another component.
   * Optional, defaults to false.
   */
  asChild?: boolean;

  /**
   * Additional class names to apply custom styles to the multi-select component.
   * Optional, can be used to add custom styles.
   */
  className?: string;
}

export const FormFieldSelectMultiSearch = React.forwardRef<
  MultiSelectRef,
  MultiSelectProps
>(
  (
    {
      options,
      assistiveText,
      disabled,
      error,
      toolTipTrigger,
      toolTipContent,
      label,
      onValueChange,
      variant,
      defaultValue = [],
      placeholder = "Select options",
      maxCount = 3,
      modalPopover = false,
      asChild = false,
      className,
      ...props
    },
    ref
  ) => {
    const [selectedValues, setSelectedValues] =
      React.useState<string[]>(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    React.useImperativeHandle(ref, () => ({
      // Expose the clearState method
      clearState() {
        setSelectedValues([]);
        onValueChange([]);
      },
      // Optionally expose other button methods
      focus() {
        buttonRef.current?.focus();
      },
      // Expose the button element if needed
      getButtonElement() {
        return buttonRef.current;
      },
    }));
    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      } else if (event.key === "Backspace" && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (option: string) => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option)
        : [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options.map((option) => option.value);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    return (
      <div className={`flex flex-col w-full text-black`}>
        <div
          className={cn(
            `px-3 text-xs font-medium`,
            error && !disabled && "text-error-10",
            toolTipContent !== "" && "flex flex-row gap-1"
          )}
        >
          {label}
          {toolTipContent && (
            <TooltipProvider delayDuration={300}>
              <Tooltip>
                <TooltipTrigger>
                  <InfoOutlinedIcon
                    className="text-info-20"
                    sx={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </TooltipTrigger>
                <TooltipContent className="max-w-[200px]">
                  <Caption1 whiteSpace="normal">{toolTipContent}</Caption1>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        <Popover
          open={isPopoverOpen}
          onOpenChange={setIsPopoverOpen}
          modal={modalPopover}
        >
          <PopoverTrigger
            asChild
            className="focus:outline focus:outline-primary-80 focus:border-primary-80"
          >
            <Button
              ref={buttonRef}
              {...props}
              onClick={handleTogglePopover}
              className={cn(
                "flex w-full p-1 rounded-xl border min-h-10 h-auto items-center justify-between bg-white hover:bg-white border-secondary-80 hover:border-secondary-50 ",
                className
              )}
            >
              {selectedValues.length > 0 ? (
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-wrap items-center">
                    {selectedValues.slice(0, maxCount).map((value) => {
                      const option = options.find((o) => o.value === value);
                      const IconComponent = option?.icon;
                      return (
                        <Badge
                          key={value}
                          className={cn(
                            "text-black bg-white border-secondary-90",
                            multiSelectVariants({ variant })
                          )}
                        >
                          {IconComponent && (
                            <IconComponent className="w-4 h-4 mr-2" />
                          )}
                          {option?.label}
                          <XCircle
                            className="w-4 h-4 ml-2 cursor-pointer"
                            onClick={(event) => {
                              event.stopPropagation();
                              toggleOption(value);
                            }}
                          />
                        </Badge>
                      );
                    })}
                    {selectedValues.length > maxCount && (
                      <Badge
                        className={cn(
                          "text-black bg-white border-secondary-90",
                          multiSelectVariants({ variant })
                        )}
                      >
                        {`+ ${selectedValues.length - maxCount} more`}
                        <XCircle
                          className="w-4 h-4 ml-2"
                          onClick={(event) => {
                            event.stopPropagation();
                            clearExtraOptions();
                          }}
                        />
                      </Badge>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <XIcon
                      className="w-4 h-4 m-1 text-black transition-colors rounded-full cursor-pointer hover:bg-error-90"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClear();
                      }}
                    />
                    <Separator
                      orientation="vertical"
                      className="flex h-full min-h-6"
                    />
                    <ChevronDown className="h-4 mx-2 text-black cursor-pointer" />
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between w-full mx-auto">
                  <Body2 className="mx-2 text-sm text-black">
                    {placeholder}
                  </Body2>
                  <ChevronDown className="h-4 mx-2 text-black cursor-pointer" />
                </div>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-auto p-0"
            align="start"
            onEscapeKeyDown={() => setIsPopoverOpen(false)}
          >
            <Command>
              <CommandInput
                placeholder="Search..."
                onKeyDown={handleInputKeyDown}
              />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    key="all"
                    onSelect={toggleAll}
                    className="cursor-pointer"
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm",
                        selectedValues.length === options.length
                          ? "bg-primary-50 text-white"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className="w-4 h-4" />
                    </div>
                    <span>(Select All)</span>
                  </CommandItem>
                  {options.map((option) => {
                    const isSelected = selectedValues.includes(option.value);
                    return (
                      <CommandItem
                        key={option.value}
                        onSelect={() => toggleOption(option.value)}
                        className="cursor-pointer"
                      >
                        <div
                          className={cn(
                            "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border",
                            isSelected
                              ? "bg-primary-50 text-white"
                              : "opacity-50 [&_svg]:invisible"
                          )}
                        >
                          <CheckIcon className="w-4 h-4" />
                        </div>
                        {option.icon && (
                          <option.icon className="w-4 h-4 mr-2 text-black" />
                        )}
                        <span>{option.label}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup>
                  <div className="flex items-center justify-between">
                    {selectedValues.length > 0 && (
                      <>
                        <CommandItem
                          onSelect={handleClear}
                          className="justify-center flex-1 cursor-pointer"
                        >
                          Clear
                        </CommandItem>
                        <Separator
                          orientation="vertical"
                          className="flex h-full min-h-6"
                        />
                      </>
                    )}
                    <CommandItem
                      onSelect={() => setIsPopoverOpen(false)}
                      className="justify-center flex-1 max-w-full cursor-pointer"
                    >
                      Close
                    </CommandItem>
                  </div>
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    );
  }
);

FormFieldSelectMultiSearch.displayName = "FormFieldSelectMultiSearch";
