import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { LoadingMaterialUI, SwitchButton } from "../UI";
import classes from "./CreateSportsFormat.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  getGenderFormat,
  getLeagueType,
  getOfficiatedStatus,
  getRules,
  getSportFormatById,
  getSports,
  getSportsFormat,
  getStandingPolicies,
  getTieBrakePolicies,
  getVenueSizes,
  getVenueTypes,
} from "../../app/venueMasterSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  CREATE_SPORT_FORMAT,
  UPDATE_SPORT_FORMAT,
} from "../../graphql/queries";
import { Rule } from "../../types/types";
import { HighlightOffRounded } from "@mui/icons-material";
import { getStaffType } from "../../app/shiftSlice";
import FormField from "../UI/FormField/FormField";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import Card from "../UI/Card/Card";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Button from "../UI/Button/Button";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import {
  GetVenueSizesQuery,
  useGetVenueSizesQuery,
  useScoringFormatsQuery,
} from "../../../src/generated/graphql";
interface StaffRequirement {
  staffTypeId: string | number;
  count: number;
}

const CreateSportFormat: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const isEditSportFormat = params.id;

  const {
    selectedRegions,
    leagueTypes,
    standingPolicies,
    tieBreakPolicies,
    sports,
    genderFormat,
    venueTypes,
    officiatedStatuses,
    sportFormat: sportFormatById,
    // venueSizes,
    isLoading,
    sportsFormatFilter,
    rules,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const { staffTypeOptions }: any = useSelector(
    (state: RootState) => state.shift
  );
  const navigate = useNavigate();
  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";

  const initialSportFormat = {
    name: "",
    sportId: "0",
    genderFormatId: "0",
    regionId: "0",
    officiatedStatusId: "0",
    leagueTypeId: "0",
    venueTypeId: "0",
    venueSizeId: "0",
    abbreviation: "",
    ruleId: 0,
    weeksOfPlayOffs: 0,
    standingsPolicyId: "0",
    tieBreakPolicyId: "0",
    defaultEmail: 0,
    peopleOnField: 0,
    minMen: 0,
    minWomen: 0,
    maxRosterSizeTeam: 0,
    maxRosterSizeIndy: 0,
    minMenIndy: 0,
    minWomenIndy: 0,
  };

  const [sportFormat, setSportFormat]: any = React.useState(initialSportFormat);
  const [errorFormat, setErrorFormat]: any = React.useState({});
  const [staffRequiredArray, setStaffRequired] = React.useState<
    StaffRequirement[]
  >([]);
  const [updateStaffRequired, setUpdateStaffRequired] =
    React.useState<boolean>(false);
  const [scoringFormatlist, setScoringFormatList] = React.useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [venueSizeList, setVenueSizeList] = React.useState<
    GetVenueSizesQuery["venueSizes"]
  >([]);

  const { data: venueSizes } = useGetVenueSizesQuery();

  useEffect(() => {
    dispatch(getVenueTypes(""));
    dispatch(getLeagueType(""));
    dispatch(getStandingPolicies(""));
    dispatch(getTieBrakePolicies(""));
    dispatch(getGenderFormat(""));
    dispatch(getSports(""));
    dispatch(getOfficiatedStatus(""));
    dispatch(getVenueSizes(""));
    dispatch(getRules(""));
    dispatch(getStaffType());
    if (isEditSportFormat) dispatch(getSportFormatById(isEditSportFormat));
  }, []);

  useEffect(() => {
    if (sportFormatById && isEditSportFormat) {
      setSportFormat(sportFormatById);
      setStaffRequired(sportFormatById.staffRequired);
    }
  }, [sportFormatById]);

  const { data: scoringFormats } = useScoringFormatsQuery();

  useEffect(() => {
    if (scoringFormats) {
      const scoringType = sports.find(
        (sport: any) => sport.id == sportFormat.sportId
      )?.scoringType.id;
      const scoringFormatsFiltered = scoringFormats?.scoringFormats.filter(
        (scoringFormat) => scoringFormat.scoringTypeId == scoringType
      );
      if (scoringFormatsFiltered)
        setScoringFormatList(
          scoringFormatsFiltered?.map((scoringFormat) => ({
            id: scoringFormat.id,
            name: scoringFormat.name,
          })) || []
        );
    }
  }, [scoringFormats, sportFormat.sportId]);

  useEffect(() => {
    if (sportFormat.venueTypeId && venueSizes && venueSizes.venueSizes) {
      const venueSizeList = venueSizes.venueSizes.filter(
        (venueSize: any) => venueSize.venueTypeId == sportFormat.venueTypeId
      );
      setVenueSizeList(venueSizeList);
    }
  }, [sportFormat.venueTypeId]);

  const [createSportFormat, { data, loading, error }]: any =
    useMutation(CREATE_SPORT_FORMAT);
  const [updateSportFormat, { dataUpdate, loadingUpdate, errorUpdate }]: any =
    useMutation(UPDATE_SPORT_FORMAT);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSportFormat({
      ...sportFormat,
      [e.target.name]: +e.target.value || e.target.value,
    });
    if (e.target.value === "")
      setErrorFormat({ ...errorFormat, [e.target.name]: true });
    else if (+e.target.value < 0)
      setErrorFormat({ ...errorFormat, [e.target.name]: true });
    else setErrorFormat({ ...errorFormat, [e.target.name]: false });
  };

  const handleChangeStaffRequirement = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedStaffRequired = [...staffRequiredArray];
    updatedStaffRequired[index] = {
      ...updatedStaffRequired[index],
      count: +e.target.value,
    };
    setStaffRequired(updatedStaffRequired);
    setUpdateStaffRequired(true);
  };

  const handleSubmit = () => {
    const errorCheck = checkFormErrors();
    if (errorCheck) {
      const sportFormatForm: any = { ...sportFormat };
      Object.keys(sportFormat).forEach(function (key) {
        sportFormatForm[key] = +sportFormat[key] || sportFormat[key];
        if (sportFormat[key] === "0") sportFormatForm[key] = 0;
      });
      if (sportFormatForm.ruleId === 0) sportFormatForm.ruleId = null;
      if (sportFormatForm.scoringFormatId === 0)
        sportFormatForm.scoringFormatId = null;
      const staffRequired: StaffRequirement[] = [];
      staffRequiredArray.forEach((staff: any) => {
        if (staff.count > 0 && staff.staffTypeId !== "0") {
          staffRequired.push({
            count: staff.count,
            staffTypeId: +staff.staffTypeId,
          });
        }
      });
      if (!isEditSportFormat) {
        createSportFormat({
          variables: {
            sportFormatArgs: {
              sportFormatInput: sportFormatForm,
              staffRequiredInput: staffRequired,
            },
          },
        }).then(() => {
          navigate(`${rootPath}/ops/sport-format`);
        });
      } else {
        const sportFormat = { ...sportFormatForm };
        delete sportFormat.staffRequired;
        updateSportFormat({
          variables: {
            sportFormatArgs: {
              id: params.id,
              sportFormatInput: sportFormat,
              staffRequiredInput: staffRequired,
              updateStaff: updateStaffRequired,
            },
          },
        }).then(() => {
          navigate(`${rootPath}/ops/sport-format`);
        });
      }
    }
  };

  const checkFormErrors = () => {
    const errorValues: any = {};
    const rosterSize =
      sportFormat.peopleOnField < +sportFormat.minMen + +sportFormat.minWomen;
    const indyTeams =
      sportFormat.rosterSize <
      +sportFormat.minMenIndy + +sportFormat.minWomenIndy;
    Object.keys(sportFormat).forEach(function (key) {
      const omit =
        key !== "minMen" &&
        key !== "minWomen" &&
        key !== "minMenIndy" &&
        key !== "minWomenIndy" &&
        key !== "defaultEmail" &&
        key !== "ruleId" &&
        key !== "weeksOfPlayOffs" &&
        key != "scoringFormatId";
      if (!sportFormat[key] && omit) errorValues[key] = true;
      else if (sportFormat[key] === "0" && omit) errorValues[key] = true;
      else if (sportFormat[key] < 0) errorValues[key] = true;
      else errorValues[key] = false;
    });
    if (rosterSize) errorValues.peopleOnField = true;
    if (indyTeams) errorValues["rosterSize"] = true;
    setErrorFormat(errorValues);
    return Object.keys(errorValues).every((k) => !errorValues[k]);
  };

  const handleStaffType = (value: string, i: number) => {
    const eventClone: StaffRequirement[] = [...staffRequiredArray];
    eventClone[i] = { staffTypeId: value, count: 0 };
    setStaffRequired(eventClone);
    setUpdateStaffRequired(true);
  };

  if (loading || loadingUpdate || isLoading) return <LoadingMaterialUI />;

  const pathsBreadcrumbs = [
    { name: "Sport Format", url: "/ops/sport-format" },
    { name: "Sport Format Details", url: "/ops/sports-format" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/vendors"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditSportFormat ? "Edit Sport format" : "Create New Sport Format"}
        </Headline1Variable>
        <Button
          variant="primary"
          height="1/2"
          onClick={() => handleSubmit()}
        >
          {isEditSportFormat ? (
            <span> Edit Sport Format</span>
          ) : (
            <span> Create Sport Format</span>
          )}
        </Button>
      </div>
      <Card className="mt-6">
        <Subtitle1>Sport Form Details</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            initialValue={sportFormat.name}
            error={errorFormat.name}
            inputChange={handleChange}
            name="name"
            label="Name"
          ></FormField>
          <FormFieldDropdown
            name="sportId"
            initialValue={sportFormat.sportId}
            error={errorFormat.sportId}
            inputChange={(value) => {
              setSportFormat({
                ...sportFormat,
                sportId: value,
              });
            }}
            label="Sport"
            placeholder="Select Sport"
          >
            {[...sports, ...[{ id: "0", name: "Select Sport" }]]}
          </FormFieldDropdown>
          <FormFieldDropdown
            name="regionId"
            initialValue={sportFormat.regionId}
            error={errorFormat.regionId}
            inputChange={(value) => {
              setSportFormat({
                ...sportFormat,
                regionId: value,
              });
            }}
            label="Region"
            placeholder="Select Region"
          >
            {[...selectedRegions, ...[{ id: "0", name: "Select Region" }]]}
          </FormFieldDropdown>
          <FormFieldDropdown
            name="genderFormatId"
            initialValue={sportFormat.genderFormatId}
            error={errorFormat.genderFormatId}
            inputChange={(value) => {
              setSportFormat({
                ...sportFormat,
                genderFormatId: value,
              });
            }}
            label="Gender Format"
            placeholder="Select Gender Format"
          >
            {[...genderFormat, ...[{ id: "0", name: "Select Gender Format" }]]}
          </FormFieldDropdown>
          <FormField
            initialValue={sportFormat.abbreviation}
            error={errorFormat.abbreviation}
            inputChange={handleChange}
            name="abbreviation"
            label="Abbreviation"
          ></FormField>
        </div>
        <div className="flex flex-row w-full gap-4 mt-6">
          <div className="w-1/5">
            <FormFieldDropdown
              name="scoringFormatId"
              initialValue={sportFormat.scoringFormatId?.toString() || "0"}
              error={false}
              inputChange={(value) => {
                setSportFormat({
                  ...sportFormat,
                  scoringFormatId: value,
                });
              }}
              label="Scoring Format"
              placeholder="Select Scoring Format"
            >
              {[
                ...[{ id: "0", name: "Select Scoring format" }],
                ...scoringFormatlist,
              ]}
            </FormFieldDropdown>
          </div>
          <div className="w-1/5">
            <FormFieldDropdown
              name="officiatedStatusId"
              initialValue={sportFormat.officiatedStatusId}
              error={errorFormat.officiatedStatusId}
              inputChange={(value) => {
                setSportFormat({
                  ...sportFormat,
                  officiatedStatusId: value,
                });
              }}
              label="Officiated Status"
              placeholder="Select Officiated Status"
            >
              {[
                ...officiatedStatuses,
                ...[{ id: "0", name: "Select Officiated Status" }],
              ]}
            </FormFieldDropdown>
          </div>
          <div className="w-1/5">
            <FormFieldDropdown
              name="leagueTypeId"
              initialValue={sportFormat.leagueTypeId}
              error={errorFormat.leagueTypeId}
              inputChange={(value) => {
                setSportFormat({
                  ...sportFormat,
                  leagueTypeId: value,
                });
              }}
              label="League Type"
              placeholder="Select League Type"
            >
              {[...leagueTypes, ...[{ id: "0", name: "Select League Type" }]]}
            </FormFieldDropdown>
          </div>
          <div className="w-1/5">
            <FormFieldDropdown
              name="venueTypeId"
              initialValue={sportFormat.venueTypeId?.toString() || "0"}
              error={errorFormat.venueTypeId}
              inputChange={(value) => {
                setSportFormat({
                  ...sportFormat,
                  venueTypeId: parseInt(value),
                  venueSizeId: 0,
                });
              }}
              label="Venue Type"
              placeholder="Select Venue Type"
            >
              {[...[{ id: "0", name: "Select Venue Type" }], ...venueTypes]}
            </FormFieldDropdown>
          </div>
          <div className="w-1/5">
            <FormFieldDropdown
              name="venueSizeId"
              initialValue={sportFormat.venueSizeId?.toString() || "0"}
              error={errorFormat.venueSizeId}
              inputChange={(value) => {
                setSportFormat({
                  ...sportFormat,
                  venueSizeId: parseInt(value),
                });
              }}
              label="Venue Size"
              placeholder="Select Venue Size"
            >
              {[...venueSizeList, ...[{ id: "0", name: "Select Venue Size" }]]}
            </FormFieldDropdown>
            <div className="text-sm text-light">
              {venueSizeList.find(
                (venueSize) => venueSize.id === sportFormat.venueSizeId
              )?.note || ""}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            type="number"
            initialValue={sportFormat.weeksOfPlayOffs?.toString()}
            error={errorFormat.weeksOfPlayOffs}
            inputChange={handleChange}
            name="weeksOfPlayOffs"
            label="Weeks of Playoffs"
          ></FormField>
          <FormFieldDropdown
            name="standingsPolicyId"
            initialValue={sportFormat.standingsPolicyId}
            error={errorFormat.standingsPolicyId}
            inputChange={(value) => {
              setSportFormat({
                ...sportFormat,
                standingsPolicyId: value,
              });
            }}
            label="Standing Policy"
            placeholder="Select Standing Policy"
          >
            {[
              ...standingPolicies,
              ...[{ id: "0", name: "Select Standing Policy" }],
            ]}
          </FormFieldDropdown>
          <FormFieldDropdown
            name="tieBreakPolicyId"
            initialValue={sportFormat.tieBreakPolicyId}
            error={errorFormat.tieBreakPolicyId}
            inputChange={(value) => {
              setSportFormat({
                ...sportFormat,
                tieBreakPolicyId: value,
              });
            }}
            label="Tie Break Policy"
            placeholder="Select Tie Break Policy"
          >
            {[
              ...tieBreakPolicies,
              ...[{ id: "0", name: "Select Tie Break Policy" }],
            ]}
          </FormFieldDropdown>
          <div className="w-1/2">
            <label className={`px-3 text-xs font-medium text-black`}>
              Default Email
            </label>
            <SwitchButton
              labelStart="NO"
              labelEnd="YES"
              height="30"
              width="50"
              checked={sportFormat.defaultEmail}
              handleChange={(value) => {
                setSportFormat({
                  ...sportFormat,
                  defaultEmail: value.target.checked ? 1 : 0,
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-row w-1/4 gap-4 mt-6">
          <FormFieldDropdown
            name="ruleId"
            initialValue={sportFormat.ruleId?.toString() || "0"}
            error={errorFormat.ruleId}
            inputChange={(value) => {
              setSportFormat({
                ...sportFormat,
                ruleId: value,
              });
            }}
            label="Rule"
            placeholder="Select Rule"
          >
            {[
              ...rules.filter((rule: Rule) => {
                return (
                  (rule.sportId == sportFormat.sportId &&
                    rule.regionId == sportFormat.regionId) ||
                  rule.regionId == null
                );
              }),
              ...[{ id: "0", name: "Select Rule" }],
            ]}
          </FormFieldDropdown>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Roster Size</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            type="number"
            initialValue={sportFormat.peopleOnField}
            error={errorFormat.peopleOnField}
            inputChange={handleChange}
            name="peopleOnField"
            label="Players on Field"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minMen || 0}
            error={errorFormat.minMen}
            inputChange={handleChange}
            name="minMen"
            label="Min Men"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minWomen || 0}
            error={errorFormat.minWomen}
            inputChange={handleChange}
            name="minWomen"
            label="Min Women"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.maxRosterSizeTeam}
            error={errorFormat.maxRosterSizeTeam}
            inputChange={handleChange}
            name="maxRosterSizeTeam"
            label="Max Roster Size"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Indy team Roster</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            type="number"
            initialValue={sportFormat.maxRosterSizeIndy}
            error={errorFormat.maxRosterSizeIndy}
            inputChange={handleChange}
            name="maxRosterSizeIndy"
            label="Max Roster Size"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minMenIndy || 0}
            error={errorFormat.minMenIndy}
            inputChange={handleChange}
            name="minMenIndy"
            label="Min Men"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minWomenIndy || 0}
            error={errorFormat.minWomenIndy}
            inputChange={handleChange}
            name="minWomenIndy"
            label="Min Women"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Staff Requirements</Subtitle1>
        <div className="flex flex-col w-1/2 gap-4 mt-6">
          <div className="flex flex-col gap-2">
            {staffRequiredArray?.length > 0 &&
              staffRequiredArray.map((staffRequired, i) => {
                return (
                  <div className="flex gap-3">
                    <FormFieldDropdown
                      name="staffType"
                      initialValue={staffRequired.staffTypeId?.toString()}
                      inputChange={(value) => {
                        handleStaffType(value, i);
                      }}
                      label="Staff Type"
                      placeholder="Select Staff Type"
                    >
                      {[
                        ...staffTypeOptions,
                        ...[{ id: "0", name: "Select Staff Type" }],
                      ]}
                    </FormFieldDropdown>
                    <FormField
                      type="number"
                      initialValue={staffRequired.count.toString()}
                      inputChange={(e) => handleChangeStaffRequirement(e, i)}
                      name="count"
                      label="Count"
                    ></FormField>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        const staffTypeClone = staffRequiredArray.slice(1);
                        setStaffRequired(staffTypeClone);
                        setUpdateStaffRequired(true);
                      }}
                    >
                      <HighlightOffRounded />
                    </IconButton>
                  </div>
                );
              })}
            {!staffRequiredArray?.length && <div>No Staff Required.</div>}
          </div>
          <hr className={classes.line}></hr>
          <Button
            variant="negative"
            onClick={() => {
              const venueClone = [...staffRequiredArray];
              venueClone.push({
                staffTypeId: "0",
                count: 0,
              });
              setStaffRequired(venueClone);
              setUpdateStaffRequired(true);
            }}
          >
            ＋Add Staff Requirement
          </Button>
        </div>
      </Card>
      <div className="mt-5 text-right">
        <Button
          variant="primary"
          height="1/2"
          onClick={() => handleSubmit()}
        >
          {isEditSportFormat ? (
            <span> Edit Sport Format</span>
          ) : (
            <span> Create Sport Format</span>
          )}
        </Button>
      </div>
    </main>
  );
};

export default CreateSportFormat;
