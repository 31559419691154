import { AllContractorShiftContextProvider } from "./AllContractorShiftContext";
import { EmailToolMarketingContextProvider } from "./EmailToolMarketingContext";
import { ImageManagerContextProvider } from "./ImageMangerContext";
import { StaffTypeContextProvider } from "./StaffTypeContext";
import { VenueOverviewContextProvider } from "./VenueOverviewContext";

export const WrapperProvider = ({ children }: any) => {
  return (
    <VenueOverviewContextProvider>
      <AllContractorShiftContextProvider>
        <EmailToolMarketingContextProvider>
          <StaffTypeContextProvider>
            <ImageManagerContextProvider>
              {children}
            </ImageManagerContextProvider>
          </StaffTypeContextProvider>
        </EmailToolMarketingContextProvider>
      </AllContractorShiftContextProvider>
    </VenueOverviewContextProvider>
  );
};
