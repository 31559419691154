import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import AddIcon from "@mui/icons-material/Add";
import Button from "../../UI/Button/Button";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import {
  Contract,
  ContractItem,
  useGetRulesPaginatedLazyQuery,
  useVenueOverviewDayLazyQuery,
  useVenuesManagementQuery,
  VenueOverviewDayQuery,
  VenueOverviewQuery,
} from "../../../generated/graphql";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  useVenueOverviewContext,
  VenueOverviewContextType,
  VenueOverviewFilterSchema,
  VenueOverviewRow,
  VenueOverviewTable,
  VenueOverviewValidDay,
} from "../../../context/VenueOverviewContext";
import { ZodFormattedError } from "zod";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { dateWithoutTimezone } from "../../../utils/timeFunctions";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import dayjs, { Dayjs } from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { getVenueTypes } from "../../../app/venueMasterSlice";
import Card from "../../UI/Card/Card";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, Row } from "@tanstack/react-table";
import { clamp } from "lodash";
import { cn } from "../../../lib/utils";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
  ContextMenuTrigger,
} from "../../UI/shadcn/context-menu";

dayjs.extend(minMax);

const VenueOverview: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, venueTypes }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/

  // Basic filter add new id types here for more filtering
  const { filter, setFilter, venueOverviewData, setVenueOverviewData } =
    useVenueOverviewContext();
  const [filterZodErrors, setFilterZodErrors] = useState<
    ZodFormattedError<VenueOverviewContextType, string>
  >({ _errors: [] });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [VenueOverviewQuery, { loading: loadingVenueOverview }] =
    useVenueOverviewDayLazyQuery();

  const { loading: loadingVenues, data: dataVenues } = useVenuesManagementQuery(
    {
      variables: {
        venueFilters: {
          regionId: [filter.regionId],
          typeId: filter.venueTypeId,
        },
      },
    }
  );

  /*** USE EFFECTS ***/
  useEffect(() => {
    dispatch(getVenueTypes(""));
  }, []);

  /*** UTILITY FUNCTIONS ***/
  async function handleApplyFilter() {
    const result = VenueOverviewFilterSchema.safeParse(filter);
    if (!result.success) {
      setFilterZodErrors(result.error.format());
      dispatch(displayAlertWarning("There was an issue the filter"));
      return;
    }
    // Creates or updates data
    await VenueOverviewQuery({
      fetchPolicy: "no-cache",
      variables: {
        venueOverviewDayFilterInput: {
          regionId: filter.regionId,
          venueId: filter.venueId,
          startDate: dateWithoutTimezone(filter.startDate),
          endDate: dateWithoutTimezone(filter.endDate),
        },
      },
      onCompleted: (data) => {
        setVenueOverviewData((prevState) => ({
          ...prevState,

          allDaysInRange: new Map(
            data.venueOverviewDay.contractDetails.flatMap((contractDetail) =>
              contractDetail.contractItems.map((item) => [
                dayjs(item.startDateTimeLocal).format("YYYY-MM-DD"),
                {
                  startDate: dayjs(item.startDateTimeLocal),
                  endDate: dayjs(item.endDateTimeLocal),
                } as VenueOverviewValidDay,
              ])
            )
          ),
          venueRows: data.venueOverviewDay.contractDetails.map(
            (contractDetail) => ({
              venue: contractDetail.venue,
              contract: contractDetail.contract,
              contractItems: contractDetail.contractItems,
              hoursOfOperation: new Set(
                contractDetail.contractItems
                  .map((item) => {
                    // Get all the hours from the beginning to the end of the contract items
                    const start = dayjs(item.startDateTimeLocal);
                    const end = dayjs(item.endDateTimeLocal);
                    // If the start and end are different then it's an overnight contact
                    let hours: number[] = [];
                    for (
                      let i = start.hour();
                      start.date() !== end.date() ? i <= 23 : i < end.hour();
                      i++
                    ) {
                      hours.push(i);
                    }
                    if (start.date() !== end.date()) {
                      for (
                        let i = 0;
                        end.minute() > 0 ? i <= end.hour() : i < end.hour();
                        i++
                      ) {
                        hours.push(i);
                      }
                    }
                    return hours;
                  })
                  .flat()
              ),
            })
          ),
        }));
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

    return;
  }

  const cols: ColumnDef<VenueOverviewRow>[] = [
    {
      header: "Id",
      id: "id",
      cell: ({ row }) => {
        return <Body1 className="w-8 min-w-8">{row.original.venue.id}</Body1>;
      },
      maxSize: 32,
      size: 32,
    },
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <div className="w-40 h-full transition-colors min-w-40 hover:bg-info-90">
            <ContextMenu>
              <ContextMenuTrigger className="flex items-center justify-center h-full">
                <Body1>{row.original.venue.name}</Body1>
              </ContextMenuTrigger>
              <ContextMenuContent className="">
                <ContextMenuItem
                  inset
                  onClick={() => {
                    if (row.original.venue.parentId) {
                      navigate(
                        `/ops/sub-venue-management/${row.original.venue.parentId}/${row.original.venue.id}`
                      );
                    } else {
                      navigate(
                        `/ops/venue-management/${row.original.venue.id}`
                      );
                    }
                  }}
                >
                  Venue
                </ContextMenuItem>
                <ContextMenuItem
                  inset
                  onClick={() => {
                    navigate(`/ops/edit-contract/${row.original.contract.id}`);
                  }}
                >
                  Contract
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          </div>
        );
      },
      maxSize: 160,
      size: 160,
    },
    {
      header: "Time",
      id: "time",
      cell: ({ row }) => {
        return (
          <div className="flex flex-col w-20 min-w-20">
            {Array.from(row.original.hoursOfOperation.values()).map((hour) => {
              return (
                <div
                  key={hour}
                  className="relative w-full h-8 border border-neutral-70"
                >
                  <Body1 key={hour}>{hour}:00</Body1>
                </div>
              );
            })}
          </div>
        );
      },
      size: 80,
      maxSize: 80,
    },
    // Dynamically add columns for each day
    ...Array.from(venueOverviewData.allDaysInRange.keys()).map((day) => {
      return {
        header: day,
        id: day,
        cell: ({ row }: { row: Row<VenueOverviewRow> }) => {
          return (
            <div className="flex flex-col">
              {Array.from(row.original.hoursOfOperation.values()).map(
                (hour) => {
                  // For each hour, calculate overlap with contract items
                  const contractItemsForDay = row.original.contractItems.filter(
                    (item) =>
                      dayjs(item.startDateTimeLocal).format("YYYY-MM-DD") ===
                      day
                  );
                  const isOvernight = hour < 10 && hour >= 0;
                  // Define the time range for the hour
                  const hourStart = dayjs(day)
                    .hour(hour)
                    .add(isOvernight ? 1 : 0, "day")
                    .minute(0)
                    .second(0);
                  const hourEnd = hourStart
                    .add(1, "hour")
                    .add(isOvernight ? 1 : 0, "day");
                  let totalOverlapMinutes = 0;
                  let isExcluded = false;
                  contractItemsForDay.forEach((item) => {
                    const itemStart = dayjs(item.startDateTimeLocal);
                    const itemEnd = dayjs(item.endDateTimeLocal);

                    // Ensure itemStart and itemEnd are valid
                    if (!itemStart.isValid() || !itemEnd.isValid()) {
                      return;
                    }
                    let overlapStart: Dayjs;
                    let overlapEnd: Dayjs;
                    let overlap: number;

                    overlapStart = dayjs.max(hourStart, itemStart) || hourStart;
                    overlapEnd = dayjs.min(hourEnd, itemEnd) || hourEnd;
                    overlap = overlapEnd.diff(overlapStart, "minutes");

                    if (overlap > 0) {
                      totalOverlapMinutes += overlap;
                    }
                    if (item.isExcluded) {
                      isExcluded = true;
                      totalOverlapMinutes = 0;
                    }
                  });
                  totalOverlapMinutes = clamp(totalOverlapMinutes, 0, 60);

                  const percentageOverlap = (totalOverlapMinutes / 60) * 100; // Each hour has 60 minutes

                  return (
                    <div
                      key={hour}
                      className="relative w-full h-8 border border-neutral-70"
                    >
                      <div
                        className={cn(
                          "absolute left-0 w-full ",
                          isOvernight ? "top-0" : "bottom-0",
                          isExcluded ? "bg-neutral-50" : "bg-success-90"
                        )}
                        style={{
                          height: `${isExcluded ? 100 : percentageOverlap}%`,
                        }}
                      ></div>
                      <Body1 className="relative min-w-40">
                        {isExcluded
                          ? "Exlusion"
                          : percentageOverlap === 100
                          ? "available"
                          : percentageOverlap === 0
                          ? "unavailable"
                          : "buffer"}
                      </Body1>
                    </div>
                  );
                }
              )}
            </div>
          );
        },
      };
    }),
  ];

  // Constants for the table
  const tableData = useMemo(() => {
    if (venueOverviewData.venueRows) return venueOverviewData.venueRows;
  }, [venueOverviewData]);
  const columns = useMemo(() => cols, [cols]);

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={false} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Venue Overview</Headline1Variable>
      </div>
      {/* Filtering section */}
      <div className="flex flex-row flex-wrap items-end w-full gap-4">
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            name="regionId"
            label="Region"
            placeholder="Select Region"
            error={filterZodErrors.filter?.regionId !== undefined}
            assistiveText={
              filterZodErrors.filter?.regionId !== undefined
                ? filterZodErrors.filter?.regionId._errors[0]
                : ""
            }
            value={filter.regionId.toString() ?? "0"}
            inputChange={(value: string) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
          >
            {[{ id: "0", name: "Select Region" }, ...selectedRegions]}
          </FormFieldSelect>
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            name="venueTypeId"
            label="Venue Type"
            placeholder="Select Venue Type"
            error={filterZodErrors.filter?.venueTypeId !== undefined}
            assistiveText={
              filterZodErrors.filter?.venueTypeId !== undefined
                ? filterZodErrors.filter?.venueTypeId._errors[0]
                : ""
            }
            value={filter.venueTypeId.toString() ?? "0"}
            inputChange={(value: string) => {
              setFilter((prevState) => ({
                ...prevState,
                venueTypeId: +value,
              }));
            }}
          >
            {[{ id: "0", name: "Select Venue Type" }, ...venueTypes]}
          </FormFieldSelect>
        </div>
        <div className="max-w-60 min-w-60">
          <DatePicker
            className="w-full"
            label="Start From Date"
            date={filter.startDate}
            setDate={(date) => {
              if (date) {
                const newStartDate = dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0);
                setFilter((prevState) => ({
                  ...prevState,
                  startDate: newStartDate.toDate(),
                }));
              }
            }}
          />
        </div>
        <div className="max-w-60 min-w-60">
          <DatePicker
            className="w-full"
            label="To Date"
            date={filter.endDate}
            setDate={(date) => {
              if (date) {
                const newEndDate = dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0);
                setFilter((prevState) => ({
                  ...prevState,
                  endDate: newEndDate.toDate(),
                }));
              }
            }}
          />
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            name="venueId"
            label="Venue"
            placeholder="Select Venue"
            error={filterZodErrors.filter?.venueId !== undefined}
            assistiveText={
              filterZodErrors.filter?.venueId !== undefined
                ? filterZodErrors.filter?.venueId._errors[0]
                : ""
            }
            value={filter.venueId.toString() ?? "0"}
            inputChange={(value: string) => {
              setFilter((prevState) => ({
                ...prevState,
                venueId: +value,
              }));
            }}
            loading={loadingVenues}
          >
            {[
              ...[{ id: "0", name: "Select Venue" }],
              ...(dataVenues !== undefined
                ? dataVenues.venues.map((venue) => {
                    return {
                      id: venue.id,
                      name: venue.name,
                    };
                  })
                : []),
            ]}
          </FormFieldSelect>
        </div>
        <Button
          variant="primary"
          onClick={handleApplyFilter}
          className="h-fit"
        >
          Apply
        </Button>
      </div>
      {tableData ? (
        <>
          {tableData.length > 0 ? (
            <div>
              <DataTable
                columns={columns}
                data={tableData}
                rowClassName="h-full"
                tableClassName="h-full"
                cellClassName="p-0"
                colsToPin={["id", "name", "time"]}
              />
            </div>
          ) : (
            <Headline2Variable>
              No contract items found with this filter
            </Headline2Variable>
          )}
        </>
      ) : (
        <Headline2Variable>Please apply a filter</Headline2Variable>
      )}
    </main>
  );
};

export default VenueOverview;
