import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ClickAwayListener } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DirectionsRunOutlinedIcon from "@mui/icons-material/DirectionsRunOutlined";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { RootState } from "@/src/app/store";
import { EPermission } from "../../utils/permissions";
import LockIcon from "@mui/icons-material/Lock";

const drawerWidth = 300;
const pagesIcons = [
  {
    id: 2,
    header: "Ops",
    router: "/ops",
    icon: <SettingsOutlinedIcon />,
  },
  {
    id: 3,
    header: "Players",
    router: "/players",
    icon: <DirectionsRunOutlinedIcon />,
  },
  {
    id: 4,
    header: "Staff",
    router: "/staff",
    icon: <GroupsRoundedIcon />,
  },
  {
    id: 5,
    header: "Marketing",
    router: "/marketing",
    icon: <StorefrontOutlinedIcon />,
  },
  {
    id: 6,
    header: "Finance",
    router: "/finance",
    icon: <MonetizationOnOutlinedIcon />,
  },
];

interface Page {
  id: number;
  header: string;
  text: string;
  subMenu: SubMenu[];
}

interface SubMenu {
  id: number;
  text: string;
  to: string;
  adminAccess: boolean;
  requiredPermissions?: EPermission[];
}

const initialPages: Page[] = [
  {
    id: 1,
    header: "ops",
    text: "Venues",
    subMenu: [
      {
        id: 1,
        text: "Vendors",
        to: "/ops/vendors",
        adminAccess: true,
      },
      {
        id: 2,
        text: "Venues",
        to: "/ops/venues",
        adminAccess: true,
      },
      {
        id: 3,
        text: "Contracts",
        to: "/ops/contracts",
        adminAccess: true,
      },
      {
        id: 4,
        text: "Venue Overview",
        to: "/ops/venue-overview",
        adminAccess: true,
      },
    ],
  },
  {
    id: 2,
    header: "ops",
    text: "Leagues",
    subMenu: [
      {
        id: 1,
        text: "Seasons",
        to: "/ops/seasons",
        adminAccess: true,
      },
      {
        id: 2,
        text: "Sport Format",
        to: "/ops/sport-format",
        adminAccess: true,
      },
      {
        id: 3,
        text: "Leagues",
        to: "/ops/leagues",
        adminAccess: true,
      },
      {
        id: 4,
        text: "Sessions",
        to: "/ops/sessions",
        adminAccess: true,
      },
      {
        id: 5,
        text: "Rules",
        to: "/ops/rules",
        adminAccess: true,
      },
      {
        id: 6,
        text: "League Wayfinder",
        to: "/ops/wayfinder",
        adminAccess: true,
      },
    ],
  },
  {
    id: 3,
    header: "ops",
    text: "Schedules",
    subMenu: [
      {
        id: 1,
        text: "Indy Teams",
        to: "/ops/free-agent-teams",
        adminAccess: true,
      },
      {
        id: 2,
        text: "Divisions",
        to: "/ops/divisions",
        adminAccess: true,
      },
      {
        id: 3,
        text: "Capacity Group Overview",
        to: "/ops/capacity-group-overview",
        adminAccess: true,
      },
    ],
  },
  {
    id: 9,
    header: "ops",
    text: "Reports",
    subMenu: [
      {
        id: 1,
        text: "Game Score Report",
        to: "/ops/game-report/unapproved-scores",
        adminAccess: true,
      },
      {
        id: 2,
        text: "Game Report",
        to: "/ops/game-report",
        adminAccess: true,
      },
    ],
  },
  {
    id: 4,
    header: "players",
    text: "Player Reports",
    subMenu: [
      {
        id: 1,
        text: "Shopping Carts",
        to: "/players/shopping-carts",
        adminAccess: true,
      },
      {
        id: 2,
        text: "Shopping Cart create/view",
        to: "/players/create-shopping-cart",
        adminAccess: true,
      },
      {
        id: 3,
        text: "Player Search",
        to: "/players/users",
        adminAccess: true,
      },
      {
        id: 4,
        text: "Registrations",
        to: "/players/registrations",
        adminAccess: true,
      },
      {
        id: 4,
        text: "Waitlist",
        to: "/players/waitlist",
        adminAccess: true,
      },
    ],
  },
  {
    id: 5,
    header: "staff",
    text: "Staff",
    subMenu: [
      {
        id: 1,
        text: "Staff Type Management",
        to: "/staffing/staff-type-management",
        adminAccess: true,
        requiredPermissions: [EPermission["MANAGE_SHIFT_STAFF"]],
      },
      {
        id: 2,
        text: "3rd Party Contractor Management",
        to: "/staffing/third-party-contractor-management",
        adminAccess: true,
        requiredPermissions: [EPermission["MANAGE_SHIFT_STAFF"]],
      },
    ],
  },
  {
    id: 6,
    header: "staff",
    text: "Shifts",
    subMenu: [
      {
        id: 1,
        text: "Create Shift",
        to: "/staffing/shift/new",
        adminAccess: true,
        requiredPermissions: [EPermission["CREATE_SHIFT"]],
      },
      {
        id: 2,
        text: "All Staff Shifts",
        to: "/staffing/all-staff-shifts",
        adminAccess: true,
      },
      {
        id: 3,
        text: "All Contrator Shifts",
        to: "/staffing/all-contractor-shifts",
        adminAccess: true,
      },
      // {
      //   id: 4,
      //   text: "Shifts Finance",
      //   to: "/staffing/shifts-finance",
      //   adminAccess: true,
      // },
    ],
  },
  {
    id: 7,
    header: "staff",
    text: "Open Shifts",
    subMenu: [
      {
        id: 1,
        text: "Requested Shifts",
        to: "/staffing/requested-shifts",
        adminAccess: true,
      },
      {
        id: 2,
        text: "My Shifts",
        to: "/staffing/my-shifts",
        adminAccess: true,
      },
      {
        id: 3,
        text: "Open Shifts",
        to: "/staffing/open-shifts",
        adminAccess: true,
      },
      {
        id: 4,
        text: "Applied Shifts",
        to: "/staffing/applied-shifts",
        adminAccess: true,
      },
    ],
  },
  {
    id: 8,
    header: "marketing",
    text: "Marketing",
    subMenu: [
      {
        id: 1,
        text: "Content Management",
        to: "/marketing/content",
        adminAccess: true,
      },
      {
        id: 2,
        text: "Email Tool",
        to: "/marketing/email-tool",
        adminAccess: true,
      },
      {
        id: 3,
        text: "Email Divisions",
        to: "/marketing/email-tool/division",
        adminAccess: true,
      },
      {
        id: 6,
        text: "Marketing Emails",
        to: "/marketing/email-tool/marketing/report",
        adminAccess: true,
      },
      {
        id: 4,
        text: "Email Template Manager",
        to: "/marketing/email-tool/email-template-manager",
        adminAccess: true,
      },
      {
        id: 5,
        text: "JAIms",
        to: "/marketing/jAIms",
        adminAccess: true,
      },
      {
        id: 6,
        text: "Image Manager",
        to: "/marketing/image-manager",
        adminAccess: true,
      },
    ],
  },
  {
    id: 9,
    header: "finance",
    text: "Finance",
    subMenu: [
      {
        id: 1,
        text: "Refund Approvals",
        to: "/finance/refund-approvals",
        adminAccess: true,
        requiredPermissions: [EPermission["REFUND_APPROVAL"]],
      },
      {
        id: 2,
        text: "Payroll Batches",
        to: "/finance/payrollBatches",
        adminAccess: true,
        requiredPermissions: [EPermission["FINANCE_PAYROLL"]],
      },
      {
        id: 3,
        text: "Shifts For Review",
        to: "/finance/shiftsForReview",
        adminAccess: true,
        requiredPermissions: [EPermission["CREATE_SHIFT"]],
      },
      {
        id: 4,
        text: "Shifts For Payroll",
        to: "/finance/shiftsForPayroll",
        adminAccess: true,
        requiredPermissions: [EPermission["FINANCE_PAYROLL"]],
      },
    ],
  },
];

const LeftNavDrawer = () => {
  const pathname: string = window.location.pathname;
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedHeader, setSelectedHeader] = useState<string>("");
  const [pages, setPages] = useState<Page[]>(initialPages);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (user?.permission) {
      // if requiredPermissions is not present in subMenu, change adminAccess to false
      const newPages = initialPages.map((page) => {
        const newSubMenu = page.subMenu.map((subMenu) => {
          if (subMenu.requiredPermissions) {
            subMenu.adminAccess = subMenu.requiredPermissions.some(
              (requiredPermission) =>
                user.permission?.some(
                  (permission) =>
                    permission.permission.name === requiredPermission
                )
            );
          }
          return subMenu;
        });

        return { ...page, subMenu: newSubMenu };
      });

      setPages(newPages);
    }
  }, [user]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        maxWidth: drawerWidth,
        width: "fit-content",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          maxWidth: drawerWidth,
          boxSizing: "border-box",
          width: "fit-content",
        },
      }}
    >
      <Toolbar />
      <Box className="overflow-auto">
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className="flex h-full">
            <List className="flex flex-col gap-5 border-2">
              {pagesIcons.map((page, index) => {
                return (
                  <ListItem
                    key={page.id}
                    disablePadding
                    className="flex flex-col px-2"
                  >
                    <div
                      className={`rounded-full ${
                        pathname.includes(page.router) && page.router
                          ? "bg-primary-90"
                          : ""
                      }`}
                    >
                      <ListItemButton
                        sx={{ padding: "8px" }}
                        color="gray"
                        onClick={() => {
                          if (page.router) {
                            setSelectedHeader(page.header);
                            setOpen(true);
                            return;
                          }
                          setOpen(!open);
                        }}
                      >
                        {page.icon}
                      </ListItemButton>
                    </div>
                    <div className="text-xs">{page.header}</div>
                  </ListItem>
                );
              })}
            </List>
            {open && (
              <Box
                className="overflow-auto"
                sx={{ paddingTop: "20px" }}
              >
                {pages
                  .filter(
                    (main) => main.header === selectedHeader.toLowerCase()
                  )
                  .map((subHeader, index) => {
                    return (
                      <div
                        key={subHeader.id}
                        className="px-2"
                      >
                        <Accordion
                          defaultExpanded={true}
                          style={{ boxShadow: "none" }}
                        >
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel-content"
                            id="panel-header"
                            sx={{
                              minHeight: "fit-content !important",
                              "& .MuiAccordionSummary-content": {
                                margin: "5px 0px !important",
                              },
                            }}
                          >
                            <Typography variant="h6">
                              {subHeader.text}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: "0px 16px 10px",
                            }}
                          >
                            <List disablePadding>
                              {subHeader.subMenu.map((subMenu, index) => {
                                return (
                                  <Link
                                    to={subMenu.to}
                                    key={subMenu.id}
                                  >
                                    <ListItem
                                      key={subMenu.id}
                                      disablePadding
                                    >
                                      <ListItemButton
                                        sx={{ padding: "5px 0px" }}
                                        onClick={() => {
                                          setOpen(false);
                                        }}
                                      >
                                        <ListItemText primary={subMenu.text} />
                                        {subMenu.adminAccess ? null : (
                                          <LockIcon />
                                        )}
                                      </ListItemButton>
                                    </ListItem>
                                  </Link>
                                );
                              })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        <Divider />
                      </div>
                    );
                  })}
              </Box>
            )}
          </div>
        </ClickAwayListener>
      </Box>
    </Drawer>
  );
};

export default LeftNavDrawer;
