import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "../Button/Button";
import Card from "../Card/Card";
import {
  GetImagesPaginatedQuery,
  ImageManagerDirectory,
  ListAllOperations,
  useCreateImageManagerDirectoryMutation,
  useCreateImageMutation,
  useGetImageManagerDirectoryTreeByNameQuery,
  useGetImagesPaginatedLazyQuery,
} from "../../../../src/generated/graphql";
import { File, Folder, Tree, TreeViewElement } from "../magic-ui/file-tree";
import { useImageManagerContext } from "../../../../src/context/ImageMangerContext";
import dayjs from "dayjs";
import Body1 from "../Text/Body/Body1";
import FormFieldControlled from "../FormField/FormFieldControlled";
import { multipartFetch } from "../../../../src/app/authSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../src/app/store";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../../src/app/globalSlice";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPEG", "PNG", "SVG", "JPG", "WEBP"];

interface VenueImagesDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  handleApplyImage: (image: string) => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @returns
 */
const VenueImagesDialog = ({
  open,
  onOpenChange,
  handleApplyImage,
}: VenueImagesDialogProps) => {
  const { selectedDirectoryId, setSelectedDirectoryId } =
    useImageManagerContext();

  const dispatch = useDispatch<AppDispatch>();

  const [imageManagerDirectoryTree, setImageManagerDirectoryTree] = useState<
    TreeViewElement[]
  >([]);
  const [imageManagerDirectoryData, setImageManagerDirectoryData] = useState<
    ImageManagerDirectory[]
  >([]);
  const [selectedDirectoryPath, setSelectedDirectoryPath] =
    useState<string>("");
  const [newFolderName, setNewFolderName] = useState<string>("");
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedDirectoryImages, setSelectedDirectoryImages] = useState<
    GetImagesPaginatedQuery["getImagesPaginated"]["images"]
  >([]);

  const flattenDirectoryTree = (directory: any) => {
    // Start with the current directory
    let flatArray = [directory];

    // Recursively flatten the children if they exist
    if (directory.children && directory.children.length > 0) {
      directory.children.forEach((child: any) => {
        flatArray = flatArray.concat(flattenDirectoryTree(child));
      });
    }

    // Return the flattened array
    return flatArray;
  };

  /** Queries */
  const { loading: loadingVenueImageManagerTree, refetch } =
    useGetImageManagerDirectoryTreeByNameQuery({
      variables: {
        name: "venue_images",
      },
      onCompleted: (data) => {
        setImageManagerDirectoryTree([data.getImageManagerDirectoryTreeByName]);
        const flattenedDirectories = flattenDirectoryTree(
          data.getImageManagerDirectoryTreeByName
        );
        setImageManagerDirectoryData(flattenedDirectories);
      },
    });

  const [getImages, { loading: loadingImages }] =
    useGetImagesPaginatedLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setSelectedDirectoryImages(data.getImagesPaginated.images);
      },
    });

  /**Mutations */

  const [
    CreateImageManagerDirectory,
    { loading: loadingCreateImageManagerDirectory },
  ] = useCreateImageManagerDirectoryMutation();

  async function handleSubmitNewFolder() {
    if (selectedDirectoryId) {
      CreateImageManagerDirectory({
        variables: {
          createImageManagerDirectoryInput: {
            parentId: selectedDirectoryId.id,
            name: newFolderName.replace(/\s/g, "_"),
          },
        },
        onCompleted: (data) => {
          setOpenCreateFolderDialog(false);
          refetch();
        },
      });
    }
  }

  const [createImage, { loading: loadingCreateImage }] =
    useCreateImageMutation();

  /** Use Effects */
  useEffect(() => {
    if (selectedDirectoryPath) {
      getImages({
        variables: {
          filePath: `image_manager/ops${selectedDirectoryPath}`,
        },
      });
    }
  }, [selectedDirectoryPath]);

  useEffect(() => {
    if (files) {
      handleUpload();
    }
  }, [files]);

  /** Function */

  const handleUpload = async () => {
    if (!files) return;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      formData.append(
        `filePath_${i}`,
        `image_manager/ops${selectedDirectoryPath}`
      ); // Add modified file name as a field
    }
    try {
      await multipartFetch
        .post("/imageManager/upload", formData)
        .then(async (response) => {
          if (response.status === 200) {
            dispatch(
              displayAlertWarning(
                `Oops! Duplicate images detected: ${response.data.duplicates.map(
                  (duplicateImage: string) => duplicateImage
                )}`
              )
            );
            return;
          }

          // wait for 1 second to allow the images to be uploaded to GCP
          const imagesForDb: {
            fileName: string;
            publicUrl: string;
            imageHash: string;
            filePath: string;
          }[] = response.data.results.map(
            (file: {
              fileName: string;
              publicUrl: string;
              hash: string;
              filePath: string;
            }) => {
              return {
                fileName: file.fileName.slice(
                  file.fileName.lastIndexOf(":") + 1
                ),
                publicUrl: file.publicUrl,
                imageHash: file.hash,
                filePath: file.filePath,
              };
            }
          );
          await createImage({
            variables: {
              createImageInput: {
                images: imagesForDb,
              },
            },
            onCompleted: (data) => {
              if (data.createImage.success) {
                dispatch(displayAlertSuccess(data.createImage.message));
              } else {
                dispatch(displayAlertError(data.createImage.message));
              }
              getImages({
                variables: {
                  filePath: `image_manager/ops${selectedDirectoryPath}`,
                },
              });
            },
            onError: (error) => {
              dispatch(displayAlertError(error.message));
            },
          });
        });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  function getFullPath(data: ImageManagerDirectory[], id: number): string {
    const idToNodeMap: { [key: number]: ImageManagerDirectory } = {};

    // Map all items by their id for quick lookup
    data.forEach((item) => {
      idToNodeMap[item.id] = item;
    });

    // Recursive function to build the path
    function buildPath(nodeId: number): string {
      const node = idToNodeMap[nodeId];
      if (!node || node.parentId === 0) {
        return node ? node.name : ""; // Return the name of the root node
      }
      return `${buildPath(node.parentId)}/${node.name}`; // Recursively prepend the parent's path
    }

    return buildPath(id);
  }

  const renderTree = (elements: TreeViewElement[]) => {
    return elements.map((element) => {
      if (element.children) {
        return (
          <Folder
            key={element.id}
            value={element.id}
            element={element.name}
            isSelect={+element.id === selectedDirectoryId?.id}
            handleFolderClicked={({ id, name }) => {
              setSelectedDirectoryId({ id: id, name: name });
              setSelectedDirectoryPath(
                getFullPath(imageManagerDirectoryData, id)
              );
            }}
          >
            {renderTree(element.children)}
          </Folder>
        );
      } else {
        return (
          <File
            key={element.id}
            value={element.id}
          >
            <p>{element.name}</p>
          </File>
        );
      }
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
        marginTop: "8vh",
      }}
    >
      <DialogContent>
        <div className="w-[70vw] max- max-h-[70vh]">
          <div className="flex justify-between items-center">
            <div className="text-lg"> Venue Image Manager</div>
            <div className="flex gap-3">
              <Button
                variant="secondary"
                onClick={() => setOpenCreateFolderDialog(true)}
              >
                Create Folder
              </Button>
              <FileUploader
                multiple={true}
                handleChange={(fileList: FileList) => {
                  setFiles(fileList);
                }}
                name="file"
                types={fileTypes}
              />
            </div>
          </div>
          <div className="flex gap-3 mt-5">
            <div className="w-1/4">
              {imageManagerDirectoryTree &&
                imageManagerDirectoryTree.length !== 0 && (
                  <Tree
                    className="p-2 overflow-hidden rounded-md bg-background"
                    elements={imageManagerDirectoryTree}
                  >
                    {renderTree(imageManagerDirectoryTree)}
                  </Tree>
                )}
            </div>
            <div className="w-3/4 flex gap-3 flex-wrap justify-center">
              {selectedDirectoryImages &&
                selectedDirectoryImages.length > 0 &&
                selectedDirectoryImages.map((image) => (
                  <div
                    className={`w-[200px] cursor ${
                      image.publicUrl === selectedImage
                        ? "border-2 border-primary-80 p-1"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedImage(image.publicUrl);
                    }}
                  >
                    <img
                      className="bg-neutral-80 rounded-sm mb-2"
                      src={image.publicUrl}
                      alt="image"
                    />
                    <div className="text-light text-sm text-wrap break-words">
                      {image.fileName}
                    </div>
                    <div className="text-light text-sm">
                      {dayjs(image.createdAt).format("DD/MM/YYYY")}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={() => onOpenChange(false)}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (selectedImage) {
              handleApplyImage(selectedImage);
              setSelectedImage(null);
              onOpenChange(false);
            }
          }}
        >
          Apply Image
        </Button>
      </DialogActions>
      <Dialog open={openCreateFolderDialog}>
        <DialogContent className="w-[40vw]">
          <div className="flex flex-col gap-4">
            <Body1>Enter a name for the new folder</Body1>
            {selectedDirectoryId && (
              <Body1>Parent folder: {selectedDirectoryId.name}</Body1>
            )}
            <FormFieldControlled
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewFolderName(event.target.value);
              }}
              value={newFolderName}
              label=""
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="primary"
            onClick={handleSubmitNewFolder}
          >
            Create Folder
          </Button>
          <Button
            variant="secondary"
            onClick={() => setOpenCreateFolderDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default VenueImagesDialog;
