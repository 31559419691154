import { createContext, useContext, useState } from "react";
import { DivisionByIdQuery, VenueOverviewDayQuery } from "../generated/graphql";
import { Pagination } from "../types/types";
import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";

export type VenueOverviewValidDay = {
  startDate: Dayjs;
  endDate: Dayjs;
};

export type VenueOverviewTable = {
  allDaysInRange: Map<string, VenueOverviewValidDay>;
  venueRows: VenueOverviewRow[];
};

export type VenueOverviewRow = {
  venue: VenueOverviewDayQuery["venueOverviewDay"]["contractDetails"][0]["venue"];
  contract: VenueOverviewDayQuery["venueOverviewDay"]["contractDetails"][0]["contract"];
  contractItems: VenueOverviewDayQuery["venueOverviewDay"]["contractDetails"][0]["contractItems"];
  hoursOfOperation: Set<number>;
};

export const VenueOverviewFilterSchema = z.object({
  regionId: z.number(),
  venueTypeId: z.number(),
  startDate: z.date(),
  endDate: z.date(),
  venueId: z.number(),
});

export type VenueOverviewFilterSchemaType = z.infer<
  typeof VenueOverviewFilterSchema
>;

export type VenueOverviewContextType = {
  filter: VenueOverviewFilterSchemaType;
  setFilter: React.Dispatch<
    React.SetStateAction<VenueOverviewFilterSchemaType>
  >;
  venueOverviewData: VenueOverviewTable;
  setVenueOverviewData: React.Dispatch<
    React.SetStateAction<VenueOverviewTable>
  >;
};

const VenueOverviewContextDefault: VenueOverviewContextType = {
  filter: {
    regionId: 0,
    venueTypeId: 0,
    startDate: dayjs(new Date())
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    endDate: dayjs(new Date())
      .add(4, "week")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    venueId: 0,
  },
  setFilter: () => {},
  venueOverviewData: {
    allDaysInRange: new Map([]),
    venueRows: [],
  },
  setVenueOverviewData: () => {},
};

const VenueOverviewContext = createContext<VenueOverviewContextType>(
  VenueOverviewContextDefault
);

export const VenueOverviewContextProvider = ({ children }: any) => {
  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    regionId: number;
    venueTypeId: number;
    startDate: Date;
    endDate: Date;
    venueId: number;
  }>({
    regionId: 0,
    venueTypeId: 0,
    startDate: dayjs(new Date())
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    endDate: dayjs(new Date())
      .add(4, "week")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    venueId: 0,
  });
  const [venueOverviewData, setVenueOverviewData] =
    useState<VenueOverviewTable>({
      allDaysInRange: new Map([]),
      venueRows: [],
    });

  const value = {
    filter,
    setFilter,
    venueOverviewData,
    setVenueOverviewData,
  };

  return (
    <VenueOverviewContext.Provider value={value}>
      {children}
    </VenueOverviewContext.Provider>
  );
};

export const useVenueOverviewContext = () => useContext(VenueOverviewContext);
